import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 22,
  pages: 21,
  zoom: 75,
  paragraphs: [
    {
      offset: 2,
      factor: 2.0,
      header: "Le Fumeur",
      image: "/le-fumeur.jpg",
      aspect: 1.0,
      text: "Dans l’espace inter-synaptique. Les encres pour vecteur, la pointe du stylo comme un échappatoire, le papier en récepteur… ."
    },
    {
      offset: 3,
      factor: 2.0,
      header: "Baigneur de Sète",
      image: "/baigneur-de-sete.jpg",
      aspect: 1,
      text:
        "Il fallait que je m’ explique les choses. c’est dans l’ inclinaison métaphorique que je pourrais peut-être comprendre mon processus de création. Il se trouve un infime endroit entre les neurones. C’ est l’ espace inter-synaptique, c’ est mon nouveau terrain de jeu, celui d’où se libère un influx, une énergie, un souffle."
    },
    {
      offset: 4,
      factor: 2.25,
      header: "Sous les ponts",
      image: "/sous-les-ponts.jpg",
      aspect: 1.5037,
      text:
        "Dans cet interstice trônent mes différents personnage dans des décors suspendus. Quand ces monstres et autres fantômes poussent les portes du tangible, mon encre agit comme un neurotransmetteur et prouve leur existence."
    },
    {
      offset: 5,
      factor: 2.0,
      header: "Bébé",
      image: "/bebe.jpg",
      aspect: 1.5,
      text:
        "Comme le chantaient en leur temps les Doors dans « Break on through », une expérience dans « l’autre côté » ne fera que raviver en nous, cette folie latente, qui nous permettra de prendre une distance nécessaire aux choses."
    },
    {
      offset: 7,
      factor: 1.75,
      header: "Un aspect gothique",
      image: "/un-aspect-gothique.jpg",
      aspect: 1.55,
      text:
        "Mes travaux, pour certains graphiques, révèlent dans des compositions parfois abstraites, que le processus créatif réside, pour ma part, sur une frontière impalpable, aux confins de la création."
    },
    { offset: 9.3, factor: 1.05, header: "fat moon", image: "/fat-moon.jpeg", aspect: 1.05, text: "Guillaume Paput." },
	
	
	{
      offset: 11,
      factor: 2.0,
      header: "Pirate",
      image: "/pirate.jpeg",
      aspect: 1.0,
      text: "Guillaume Paput"
    },
    {
      offset: 12,
      factor: 2.0,
      header: "la-puce",
      image: "/la-puce.png",
      aspect: 1.75,
      text:
        "Guillaume Paput"
    },
    {
      offset: 13,
      factor: 2.00,
      header: "Bataille",
      image: "/bataille.jpg",
      aspect: 0.90,
      text:
        "Guillaume Paput"
    },
	    {
      offset: 14,
      factor: 2.25,
      header: "Surveillance",
      image: "/surveillance.jpg",
      aspect: 1.00,
      text:
        "Guillaume Paput"
    },
   
   
   {
      offset: 16,
      factor: 2.0,
      header: "Tricératops",
      image: "/1.jpeg",
      aspect: 1.5,
      text: "Format Raisin : 65*50 cm Guillaume Paput"
    },
    {
      offset: 17,
      factor: 2.0,
      header: "Marine",
      image: "/2.jpeg",
      aspect: 1.5,
      text:
        "Format Raisin : 65*50 cm Guillaume Paput"
    },
    {
      offset: 18,
      factor: 2.25,
      header: "Fantomask",
      image: "/3.jpeg",
      aspect: 1.5037,
      text:
        "Format Raisin : 65*50 cm Guillaume Paput"
    },
	   
		{
      offset: 19,
      factor: 2.25,
      header: "Le moineau",
      image: "/4.jpeg",
      aspect: 1.5037,
      text:
        "Format Raisin : 65*50 cm Guillaume Paput"
    },
	{
      offset: 20,
      factor: 2.25,
      header: "Chien huant",
      image: "/5.jpeg",
      aspect: 1.5037,
      text:
        "Format Raisin : 65*50 cm Guillaume Paput"
    }
	
	
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 },
	{ offset: 12, color: "#000", height: 13 },
    { offset: 20, color: "#000", height: 20 },

  
  ],
 
  top: createRef()
}

export default state
